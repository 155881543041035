import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Search used: data-controller="team-select"

  updateteam(event) {
    console.log("@updateteam event:", event.target.value);
    const teamId = event.target.value;

    fetch(`/admin/select_team?team_id=${teamId}`, {
      method: "POST", // or 'PUT'
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
      body: "", // data can be `string` or {object}!
    }).then((response) => (window.location.href = `/admin`));
  }
}
