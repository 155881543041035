import { Controller } from "@hotwired/stimulus";

// Work with toggle.class to control toggle icon display

// Search used: controller: "toggle"
// Search used: data-controller="toggle" data-toggle-tid-value=""
export default class extends Controller {
  static values = { tid: String, tclass: String, on: String };

  #target = null;

  connect() {
    this.target = document.getElementById(this.tidValue);

    if (!this.target) {
      console.warn("Not found element", this.tidValue);
      return;
    }

    if (this.target.classList.contains(this.tclassValue)) {
      this.onValue = true;
    } else {
      this.onValue = false;
    }
  }

  toggle(e) {
    e.preventDefault();
    if (!this.target) {
      console.warn("Not found element", this.tidValue);
      return;
    }
    this.target.classList.toggle(this.tclassValue);

    if (this.target.classList.contains(this.tclassValue)) {
      this.onValue = true;
    } else {
      this.onValue = false;
    }

    let inputs = this.target.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      let input_e = inputs[i];
      if (input_e.getAttribute("type") == "text") {
        input_e.focus();
        break;
      }
    }
  }

  // click@window->toggle#hide
  hide(event) {
    let target = document.getElementById(this.tidValue);
    if (event && (this.element.contains(event.target) || this.element.contains(event.target))) {
      console.log("Hide: click inside");
      // event.preventDefault(); // I don't remeber why I did it, but i need this line to be commented
      return;
    }

    var style = window.getComputedStyle(target);
    if (style.visibility === "hidden" || style.display === "none") {
      return;
    }
    target.classList.add(this.tclassValue);
  }
}
