import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "display" ]

  show(event) {
    console.log(event.currentTarget.dataset)
    event.preventDefault()
    console.log(event.currentTarget.dataset)
    const teamId = event.currentTarget.dataset.teamId
    const userId = event.currentTarget.dataset.userId
    const displayElement = document.getElementById(`optoken-${userId}`)

    fetch(`/team/${teamId}/users/${userId}/show_optoken`, {
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      },
    })
    .then(response => response.json())
    .then(data => {
      displayElement.textContent = data.optoken
      setTimeout(() => {
        displayElement.textContent = "*******"
      }, 10000)
    })
    .catch(error => console.error('Error:', error))
  }
}