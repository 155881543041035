import { Controller } from "@hotwired/stimulus";

import { humanFileSize } from "../libs/humanFileSize";

export default class extends Controller {
  static targets = ["connectedcount", "totalcount", "bytesent", "bytesrecv"];
  connect() {
    fetch(`/api/v1/broker/status`, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    }).then((response) => {
      response.json().then((data) => {
        console.log(data);
        // check if data.broker_clients_connected defined
        if (data.broker_clients_connected) {
          this.connectedcountTarget.innerText = data.broker_clients_connected;
        }
        if (data.broker_clients_total) {
          this.totalcountTarget.innerText = data.broker_clients_total;
        }
        if (data.broker_bytes_sent) {
          // Format the bytes
          this.bytesentTarget.innerText = humanFileSize(data.broker_bytes_sent, true, 1);
        }
        if (data.broker_bytes_received) {
          // Format the bytes
          this.bytesrecvTarget.innerText = humanFileSize(data.broker_bytes_received, true, 1);
        }
      });
    });
  }
}
